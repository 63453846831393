
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { QuestionInterface, AnswerInterface } from '@/interface/BaseInterface'
  import { forEach, replace, filter } from 'lodash'

  @Component
  export default class MultipleDropdowns extends Vue {
    @Prop({ required: true, type: Object }) data!: QuestionInterface
    @Prop({ required: true, type: Number }) index!: number

    result: Record<string, string> = {}

    get getContent() {
      // return handleConvertText(this.data.content, this.data.keys || [])
      return this.replaceString(this.data.content, this.data.keys || [], this.data.answers || [])
    }

    replaceString(text: string, keys: string[], answers: AnswerInterface[]) {
      let result = text
      forEach(keys, (value, key) => {
        // result = replace(result, new RegExp(`[${value}]`, 'g'), `<input name="${value}"/>`)
        const listAnswer = filter(answers, elm => {
          return elm.key === value
        })
        let replaceData = `<select id="question_${this.index + key}" name="question_${this.index + key}_${this.data._id}_${value}">`
        forEach(listAnswer, a => {
          replaceData += `<option value="${a._id}">${a.content}</option>`
        })
        // <option value="volvo">Volvo</option>
        // <option value="saab">Saab</option>
        // <option value="mercedes">Mercedes</option>
        // <option value="audi">Audi</option>
        replaceData += `</select>`
        result = replace(result, `[${value}]`, replaceData)
      })
      return result
    }
  }
