
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { QuestionInterface } from '@/interface/BaseInterface'
  import { forEach, replace } from 'lodash'
  // import { handleConvertText } from '@/utils/linkifyHelper'

  @Component
  export default class MultipleBlanks extends Vue {
    @Prop({ required: true, type: Object }) data!: QuestionInterface
    @Prop({ required: true, type: Number }) index!: number

    result: Record<string, string> = {}

    get getContent() {
      // return handleConvertText(this.data.content, this.data.keys || [])
      return this.replaceString(this.data.content, this.data.keys || [])
    }

    replaceString(text: string, keys: string[]) {
      let result = text
      forEach(keys, (value, key) => {
        // result = replace(result, new RegExp(`[${value}]`, 'g'), `<input name="${value}"/>`)
        result = replace(
          result,
          `[${value}]`,
          `<input id="question_${this.index + key}" placeholder="Question ${this.index + key}" name="question_${this.index + key}_${this.data._id}_${value}"/>`
        )
      })
      return result
    }
  }
