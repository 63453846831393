
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { QuestionInterface } from '@/interface/BaseInterface'
  import BaseEditor from '@/components/base/editor/BaseEditor.vue'

  @Component({
    components: { BaseEditor }
  })
  export default class EssayQuestion extends Vue {
    @Prop({ required: true, type: Object }) data!: QuestionInterface
    @Prop({ required: true, type: Number }) index!: number

    input = ''

    handleChange(string: string) {
      this.input = string
      this.$emit('choice', string)
    }
  }
