import { render, staticRenderFns } from "./MutipleChoice.vue?vue&type=template&id=f28f9764&scoped=true&"
import script from "./MutipleChoice.vue?vue&type=script&lang=ts&"
export * from "./MutipleChoice.vue?vue&type=script&lang=ts&"
import style0 from "./MutipleChoice.vue?vue&type=style&index=0&id=f28f9764&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f28f9764",
  null
  
)

export default component.exports