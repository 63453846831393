
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { QuestionInterface, FileResultInterface } from '@/interface/BaseInterface'
  import { size } from 'lodash'

  @Component
  export default class FileUploadQuestion extends Vue {
    @Prop({ required: true, type: Object }) data!: QuestionInterface
    @Prop({ required: true, type: Number }) index!: number

    result: FileResultInterface = {
      path: '',
      filename: ''
    }

    handleSuccess(data: FileResultInterface[]) {
      if (size(data) > 0) {
        this.result = data[0]
      }
    }
  }
