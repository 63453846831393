
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { AnswerInterface } from '@/interface/BaseInterface'

  @Component
  export default class MultipleAnswers extends Vue {
    @Prop({ required: true, type: Array }) data!: AnswerInterface[]
    @Prop({ required: true, type: Number }) index!: number

    result = []

    handleChoice(value: string) {
      this.$emit('choice', value)
    }
  }
