
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { QuestionInterface } from '@/interface/BaseInterface'

  @Component
  export default class ShortAnswer extends Vue {
    @Prop({ required: true, type: Object }) data!: QuestionInterface
    @Prop({ required: true, type: Number }) index!: number

    input = ''

    handleChange(string: string) {
      this.$emit('choice', string)
    }
  }
